* {
  @apply antialiased;
}

html {
  scroll-behavior: smooth;
}

/* To crop line height spacing for the primary buttons */
@mixin lhCrop($line-height) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }

  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-bottom: (calc((1 - #{$line-height}) * 0.5em));
  }
}

.text-to-crop {
  @include lhCrop(1.1); //line-height: 1.1
}
