.Menu {
  @screen lg {
    p,
    button,
    a,
    li,
    svg {
      color: white;
      mix-blend-mode: difference;
      filter: invert(1);
    }
  }
}
