.CookieModal {
  transition: $transition;
  pointer-events: all !important;

  img {
    width: 6.25rem;
    height: 6.25rem;

    @screen md {
      width: 9.375rem;
      height: 9.375rem;
    }
  }

  &:hover {
    transform: rotate(180deg);
  }
}
