.MediaCarouselBlock {
  &__slide {
    max-width: 20rem;

    @screen md {
      max-width: 42.625rem;
      width: 30vw;
    }

    @screen lg {
      max-width: 42.625rem;
      width: 46vw;
    }

    &:first-of-type {
      width: 58vw;

      @screen md {
        width: 26vw;
      }

      @screen lg {
        width: 30vw;
      }
    }

    &:not(:last-of-type) {
      margin-right: 0.625rem;
    }
  }
}
