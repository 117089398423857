.EmailSubscribe {
  top: -0.3rem;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    @apply border-0;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px black inset;
  }

  @screen md {
    top: -0.1rem;
  }

  @screen lg {
    top: -0.8rem;
  }
}
