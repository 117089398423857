// Temporary CSS currently not linked to a component.
// TODO: Refactor into Tailwind and proper filename once there is a ProductGrid component.

.ProductGridWrapper {
  // TODO: Implement this as a prop in components/Product/GridItem.tsx
  &__style-first-cell-enlarged {
    @media (min-width: 1000px) {
      > :first-child {
        grid-column: 1/3;
        grid-row: 1/3;

        .ProductGridItem__image {
          height: 40rem;
          max-width: 45rem;
        }
      }
    }

    @media (min-width: 1400px) {
      > :first-child {
        .ProductGridItem__image {
          height: 50rem;
          max-width: 60rem;
        }
      }
    }
  }
}
