// Product Grid Item. Styles for src/components/Product/GridItem.tsx.

.ProductGridItem {
  display: grid;

  //Aspect ratio
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(480 / 499 * 100%);
    grid-area: 1 / 1 / 2 / 2;
  }

  &__inner-container {
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
  }

  &__image {
    width: 130%;
  }

  &__title,
  &__price {
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0.0275rem;
  }

  &[data-fade-in='true'] {
    .ProductGridItem__image {
      animation: 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 normal forwards
        animation-fade-in;
      pointer-events: all;
    }
  }
}
