.Footer {
  &__button {
    padding: 0.2rem 0.25rem 0 0.2rem;
    @include lhCrop(1); //line-height: 1

    @screen lg {
      padding-top: 0.325rem;
      @include lhCrop(1.1); //line-height: 1.1
    }
  }
}
