@layer base {
  // ITC Garamond (Light)
  @font-face {
    font-family: 'ITCGaramondStdLtCond';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url(/fonts/ITCGaramondStdLtCond.woff2) format('woff2'),
      url(/fonts/ITCGaramondStdLtCond.woff) format('woff'),
      url(/fonts/ITCGaramondStdLtCond.otf) format('otf');
  }

  // ITC Garamond (Light italic)
  @font-face {
    font-family: 'ITCGaramondStdLtCond';
    font-display: swap;
    font-style: italic;
    font-weight: 300;
    src: url(/fonts/ITCGaramondStdLtCondIta.woff2) format('woff2'),
      url(/fonts/ITCGaramondStdLtCondIta.woff) format('woff'),
      url(/fonts/ITCGaramondStdLtCondIta.otf) format('otf');
  }

  // ABC Diatype (Light)
  @font-face {
    font-family: 'ABCDiatypeLight';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url(/fonts/ABCDiatype-Light.woff2) format('woff2'),
      url(/fonts/ABCDiatype-Light.woff) format('woff'),
      url(/fonts/ABCDiatype-Light.otf) format('otf');
  }

  // ABC Diatype (Light italic)
  @font-face {
    font-family: 'ABCDiatypeLight';
    font-display: swap;
    font-style: italic;
    font-weight: 300;
    src: url(/fonts/ABCDiatype-LightItalic.woff2) format('woff2'),
      url(/fonts/ABCDiatype-LightItalic.woff) format('woff'),
      url(/fonts/ABCDiatype-LightItalic.otf) format('otf');
  }

  // Index Logotype (Light)
  @font-face {
    font-family: 'IndexLogotype';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url(/fonts/IndexLogotypev1.1-Regular.woff2) format('woff2'),
      url(/fonts/IndexLogotypev1.1-Regular.woff) format('woff');
  }

  // Monospace
  @font-face {
    font-family: 'Input';
    font-display: swap;
    src: url('/fonts/Input-Regular_(InputSans-Light).woff2') format('woff2'),
      url('/fonts/Input-Regular_(InputSans-Light).woff') format('woff'),
      url('/fonts/Input-Regular_(InputSans-Light).ttf') format('otf');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Input';
    font-display: swap;
    src: url('/fonts/Input-Italic_(InputSans-LightItalic).woff2')
        format('woff2'),
      url('/fonts/Input-Italic_(InputSans-LightItalic).woff') format('woff'),
      url('/fonts/Input-Italic_(InputSans-LightItalic).ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Input';
    font-display: swap;
    src: url('/fonts/Input-Bold_(InputSans-Regular).woff2') format('woff2'),
      url('/fonts/Input-Bold_(InputSans-Regular).woff') format('woff'),
      url('/fonts/Input-Bold_(InputSans-Regular).ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Input';
    font-display: swap;
    src: url('/fonts/Input-BoldItalic_(InputSans-Regular).woff2')
        format('woff2'),
      url('/fonts/Input-BoldItalic_(InputSans-Regular).woff') format('woff'),
      url('/fonts/Input-BoldItalic_(InputSans-Regular).ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
  }
}
