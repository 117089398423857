.RichText {
  strong {
    font-weight: 600;
  }

  &__unordered {
    li {
      list-style-type: '—';
      padding-inline-start: 15px;
    }
    &__sanSerif {
      margin-left: 1.75rem;
    }
    &__serif {
      margin-left: 2.5rem;
    }
    ul {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
      &__serif {
        margin-left: 2.6rem;
      }
      &__sanSerif {
        margin-left: 2rem;
      }
    }
  }

  &__ordered {
    list-style: numbered;
    ::marker {
      font-size: 2rem;
      font-weight: 300;
    }
    &__sanSerif {
      margin-left: 3.5rem;
      ol {
        margin-left: 1.7rem;
      }
    }
    &__serif {
      margin-left: 1.5rem;
      li {
        padding-left: 1.8rem;
      }
      ol {
        margin-left: 2rem;
      }
    }
    ol {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
      li {
        list-style-type: '—';
        padding-inline-start: 15px;
      }
      ol {
        margin-left: 1.8rem;
      }
      span {
        padding-left: 0;
      }
    }
  }

  &__code {
    margin-right: auto;

    pre {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
}
