.GlobalNav {
  &__logo {
    padding-bottom: 0.375rem;
  }

  @supports (-moz-appearance: none) {
    &__logo {
      padding-bottom: 0;
      padding-top: 0.25rem;
    }
  }
}

#nprogress .bar:after {
  position: absolute;
  height: 3px;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0px 3px 4px 2px rgba(45, 172, 97, 0.6);
}
