.MediaBlock {
  &__youtube {
    iframe {
      min-height: 32vh;
      @screen md {
        min-height: 55vh;
      }
    }
  }
  &__video {
    video {
      position: relative;
      height: 0;
      padding-bottom: 0;
      margin: 0 auto;
      min-height: 32vh;
      @screen md {
        min-height: 55vh;
      }
    }
  }
  &__videoParent {
    min-height: 32vh;
    @screen md {
      min-height: 55vh;
    }
    video {
      min-height: 32vh;
      @screen md {
        min-height: 55vh;
      }
    }
  }
  &__vimeo {
    padding: 56.25% 0 0 0;
    position: relative;
  }

  &__vimeo iframe,
  &__vimeo object,
  &__vimeo embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
