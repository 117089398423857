@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts';
@import './vars';
@import './global';
@import './styleguide';

[data-whatintent='mouse'] *:focus {
  outline: none;
}

[data-whatintent='touch'] *:focus {
  outline: none;
}

@layer base {
  :root {
    --navbar-height: #{$navbar-height};
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
